.professional-list {
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .professional-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .professional-card {
    border: 2px solid #3498db;
    border-radius: 12px;
    margin: 10px;
    padding: 10px;
    width: 400px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: scale(1.02);
      }
  }
  
  .card-header {
    padding: 15px;
    text-align: center;
    background-color: #3498db;
    color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .card-header img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  .card-body {
    padding: 15px;
  }
  
  /* ul {
    list-style: none;
    padding: 0;
  } */
  
  .professional-text-primary {
    color: #3498db;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
  
  .professional-btn {
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
  }
  
  .view-profile-btn {
    background-color: #2ecc71;
  }
  
  .call-btn {
    background-color: #e74c3c;
  }
  
  .info-btn {
    width: 100%;
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    .professional-card {
      width: 100%;
    }
  }
  