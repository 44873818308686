@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
/* Global Styles */

*{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
  scrollbar-color: transparent transparent; 
}
*::-webkit-scrollbar{
  display: none; 
}


body {
  font-family: "Lato",'Ubuntu', sans-serif;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}



/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
/* .p-1 {
  padding: 1rem;
} */
/* .p-2 {
  padding: 2rem;
} */
/* .p-3 {
  padding: 3rem;
} */
.py {
  padding: 0.5rem 0;
}
/* .py-1 {
  padding: 1rem 0;
} */
/* .py-2 {
  padding: 2rem 0;
} */
/* .py-3 {
  padding: 3rem 0;
} */

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
/* .m-2 {
  margin: 2rem;
} */
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
/* .my-2 {
  margin: 2rem 0;
} */
/* .my-3 {
  margin: 3rem 0;
} */






.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.sub-btn {
  margin: 0px;
  margin-top: 20px;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

/* .btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
} */

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

/* .form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.3rem;
  font-size: 1.0rem;
  border: 1px solid #ccc;
} */

.form input[type='submit'],
/* button {
  font: inherit;
} */

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: relative;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: inline;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Landing Page */
.landing {
  position: relative;
  /* background: url('./img/employment.jpg') no-repeat center center/cover; */
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo > div:first-child {
  flex: 7 1;
  flex-basis: 70%;
}

.profile-github > div:last-child {
  flex: 3 1;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post > div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* .flex {
  display: flex;
} */

.flex-wrap {
  flex-wrap: wrap;
}

/* .flex-col {
  flex-direction: column !important;
} */

.gap-12px {
  grid-gap: 12px;
  gap: 12px
}

.row-gap-16px {
  grid-row-gap: 16px;
  row-gap: 16px
}

.justify-between {
  justify-content: space-between;
}

/* .job {
  width: 300px;
} */

.width-50 {
  width: 50%;
}

.width-30 {
  width: 30%
}

.height-30px {
  height: 40px;
}

.margin-0 {
  margin: 0px !important;
}

.rounded-4 {
  border-radius: 4px;
}

.text-xs {
	font-size: 12px;
	line-height: 16px;
}
/* .text-sm {
	font-size: 14px;
	line-height: 20px;
} */
.text-base {
	font-size: 16px;
	line-height: 24px;
}
/* .text-xl {
	font-size: 20px;
	line-height: 28px;
} */
/* .text-lg {
	font-size: 18px;
	line-height: 28px;
} */
/* .text-2xl {
	font-size: 24px;
	line-height: 32px;
} */

.footer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.footer .footer-inside {
  padding: 24px 0 8px;
  font-size: 14px;
}

.u7OzwiLi {
  opacity: 1 !important;
}

.BdwL79Y_ {
  background: #000;
  z-index: 3;
  position: relative;
  width: 100%;
}

/* ********************** Invoices  ************************ */
/* Register Professional */


.invoice-card {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 10px 2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 25em;
  width: 22em;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.15);
}

.invoice-card > div {
  margin: 5px 0;
}

.invoice-title {
  flex: 3 1;
}

.invoice-title #date {
  display: block;
  margin: 8px 0;
  font-size: 12px;
}

.invoice-title #main-title {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

.invoice-title #main-title h4 {
  letter-spacing: 2.5px;
}

.invoice-title span {
  color: rgba(0, 0, 0, 0.4);
}

.invoice-details-doc {
  flex: 1 1;
  border-top: 0.5px dashed grey;
  border-bottom: 0.5px dashed grey;
  display: flex;
  align-items: center;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table thead tr td {
  font-size: 12px;
  letter-spacing: 1px;
  color: grey;
  padding: 8px 0;
}

.invoice-table thead tr td:nth-last-child(1),
.row-data td:nth-last-child(1),
.calc-row td:nth-last-child(1)
{
  text-align: right;
}

.invoice-table tbody tr td {
    padding: 8px 0;
    letter-spacing: 0;
}

.invoice-table .row-data #unit {
  text-align: center;
}

.invoice-table .row-data span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.invoice-footer {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.invoice-footer #later {
  margin-right: 5px;
}

/* Tiles for Register Page  */

.card-category-1 div {
  display: inline-block;
}
 
.card-category-1 > div {
  margin: 10px 5px;
  text-align: left;
}
 
/* Basic Card */
.basic-card {
  width: 300px;
  position: relative;
  -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}
 
.basic-card .card-content {
  padding: 30px;
}
 
.basic-card .card-text {
  line-height: 1.6;
}
 
.basic-card .card-link {
  padding: 25px;
  width: -webkit-fill-available;
}
 
.basic-card .card-link a {
  text-decoration: none;
  position: relative;
  padding: 10px 0px;
}
 
.basic-card .card-link a:after {
  top: 30px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 0;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}
 
.basic-card .card-link a:hover:after {
  width: 100%;
  left: 0;
}
 
.basic-card-aqua {
  background-image: linear-gradient(to bottom right, #00bfad, #99a3d4);
}

.basic-card-aqua-job {
  background-image: linear-gradient(to bottom right, hsl(125, 100%, 37%), #99a3d4);
}

.basic-card-aqua-service {
  background-image: linear-gradient(to bottom right, hsl(53, 100%, 37%), #ff0000);
}

.basic-card-aqua-service-provider {
  background-image: linear-gradient(to bottom right, hsl(178, 100%, 37%), #a3d499);
}
 
 
.basic-card-aqua .card-content,
.basic-card .card-link a {
  color: #fff;
}
 
.basic-card-aqua .card-link {
  border-top: 1px solid #82c1bb;
}
 
.basic-card-aqua .card-link a:after {
  background: #fff;
}

/* Tiles closed */

/* Mobile Styles */
@media (max-width: 700px) {

  .form .form-group {
    margin: 1.2rem 0;
  }

  .form {
    display: block;
    margin-top: 0.3rem;
    color: #888;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 1fr;
  }
  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }

  .form-wrapper {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
  
  .input-field {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    padding: 0.5rem 0rem;
  }
  .input-field label {
    color: rgb(133, 133, 133);
    font-size: 14px;
  }
  .main-heading {
    color: #212121;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }
  .sub-text {
    color: #424242;
    font-size: 15px;
  }
  .wrapper {
    max-width: 580px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 1rem;
    margin: 0 auto;
  }
  .sub-text {
    color: #424242;
    font-size: 1rem;
  }

  .job {
    width: 300px;
  }
}

.text-gradient {
  background-image: rgb(233,131,21);
  background-image: linear-gradient(52deg, rgba(233,131,21,0.8637413394919169) 36%, rgba(10,137,1,1) 100%);
  -webkit-background-clip: text; /* Clip the background to the text */
  color: transparent;
}

.btn-grad {
  background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%);
  margin: 10px;
  padding: 10px 40px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.neo{
  border-radius: 22px;
  background: #ffffff;
  box-shadow:  5px 5px 10px #dbdbdb,
               -5px -5px 10px #ffffff;
}

.inset-shadow{
  border-radius: 11px;
  background: linear-gradient(145deg, #d3d2d2, #ffffff);
  box-shadow:  5px 5px 10px #dbdbdb,
               -5px -5px 10px #ffffff;
}
.header{
    width: 100%;
    height: 56px;
    /* background: #2874f0; */
    /* background: #26416d; */
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(to right, #020D51, #19B0DC);
}
.subHeader{
    max-width: 1100px;
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-end;
}

.logo{
    width: 137px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.logo .logoimage{
    width: 75px;
}
.exploreText{ 
    font-size: 10px;
    font-style: italic; 
}
.plusText{ 
    font-size: 10px; 
    margin: 0 2px; 
    text-transform: capitalize;
    font-style: italic;
    color: yellow;
  }
.logo .goldenStar{
    width: 10px;
    height: 10px;
}

.searchInputContainer {
    width: 560px;
    position: relative;
    background: #fff;
    height: 36px;
    border-radius: 1px;
    overflow: hidden;
    display: flex;
  }
.searchInput {
    width: 518px;
    border: 0;
    height: 36px;
    outline: none;
    box-sizing: border-box;
    padding: 0 10px;
  }
.searchIconContainer {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
  }
  .rightMenu{
    display: flex;
    flex: 1 1;
    /* justify-content: flex-end;  For desktop view*/
    justify-content: space-between;
    align-items: center;
  }
.loginButton {
    display: block;
    width: 100px;
    height: 30px;
    background: #fff;
    text-align: center;
    line-height: 30px;
    color: #2874f0;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }
.more, .fullName, .db {
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
  }
.cart {
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px
  }

.authContainer{
  color: #212121;
  margin: 0 auto;
  border-radius: 2px;
  height: 528px;
  max-width: 750px;
  min-width: 650px;
}
.row{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  /* height: 528px; */
    max-width: 750px;
    /* min-width: 650px; */
}

.leftspace{
  width: 40%;
  height: 100%;
  color: #fff;
  letter-spacing: 1px;
  /* background-image: url('../../images/login-image.png'); */
  background-color: #2874f0;
  background-repeat: no-repeat;
  background-position: center 85%;
  padding: 40px 33px;
  box-sizing: border-box;
}

.rightspace{
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
}

.loginInputContainer{
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  margin: 40px 0;
}
/* Material Modal */
.modalFixedBg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    background-color: rgba(0,0,0,.6);
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalContainer{
    min-width: 400px;
    max-width: 90%;
    min-height: 200px;
    max-height: 90vh;
    background: #fff;
    border-radius: 4px;
    margin: 0 auto;
    overflow-x: hidden;
}
.modalClose{
    position: absolute;
    color: #fff;
    right: 15px;
    font-size: 20px;
}

/* Material Input */
.materialInput{
    width: 100%;
    min-width: 300px;
    height: 30px;
    border-bottom: 2px solid #2874f0;
    position: relative;
    /* background-color: red; */
    margin-top: 25px;
  }
  .materialInput input{
    width: 100%;
    flex: 1 1;
    outline: none;
    border: none;
    z-index: 1;
    background: transparent;
    line-height: 30px;
  }
  .materialInput .label{
    position: absolute;
    line-height: 30px;
    color: grey;
    z-index: 0;
    font-size: 12px;
    letter-spacing: 1px;
    transition: all 0.1s ease-in-out;
  }
  .materialInput .label.focus{
    top: -20px !important;
  }

  /* Material Button */
.materialButton{
    width: 100%;
    display: inline-block;
    padding: 15px;
    font-size: 15px;
    outline: none;
    background-color: #fb641b;
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    border: none;
    cursor: pointer;
    border-radius: 2px;
}
.materialButton:hover{
    box-shadow: 0 4px 6px 0 rgba(0,0,0,.12);
}

/* Material dropdown */
.headerDropdownContainer{
    position: relative;
    display: inline-block;
}
.firstmenu{
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
   
  }
.dropDown{
  right: 50%;
  position: absolute;
  width: 240px;
  transform: translateX(50%);
  z-index: 1;
  display: none;
}
.dropdownMenu{
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,.2);
  background: #fff;
  border-radius: 2px;
}
.headerDropdownContainer:hover .dropDown{
    display: block;
  }
  .upArrowContainer{
    position: relative;
    width: 100%;
    height: 10px;
  }
  .upArrow{
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    left: 50%;
    transform: translateX(-50%);
  }
  .headerDropdownMenu{
    margin: 0;
    padding: 0;
    background: #fff;
  }
  .headerDropdownMenu li{
    list-style: none;
  }
  .headerDropdownMenu li a{
    display: block;
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    color: #212121;
    text-decoration: none;
  }
  .headerDropdownMenu li a:hover{
    background-color: #f0f0f0;
    cursor: pointer;
  }

  .anchorButton{
    background: transparent;
    border: none;
    letter-spacing: 1px;
  }

  .card{
    width: 100%;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px 0px !important;
}
.cardHeader{
    display: flex;
    justify-content: space-between;
    padding: 5px 5px;
    border-bottom: 1px solid #cecece;
}
.menuHeader{
    width: 100%;
    height: 40px;
    background: #fff;
    border-bottom: 1px solid #cecece;
    box-shadow: 0 2px 2px -2px #333;
}
.menuHeader ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.menuHeader > ul{
    display: flex;
    margin: 0 50px;
    position: relative;
}
.menuHeader > ul > li > span{
    display: block;
    line-height: 40px;
    cursor: pointer;
    padding: 0 20px;
    font-size: 14px;
}
.menuHeader > ul > li > span:hover{
    color: #2874f0;
}
.menuHeader > ul > li > ul{
    position: absolute;
    background: #fff;
    left: 0;
    right: 0;
    display: none;
    border: 1px solid #cecece;
    z-index: 1;
}
.menuHeader > ul > li:hover ul{
    display: block;
}
.menuHeader > ul > li > ul > li{
    margin: 0 20px;
    min-width: 150px;
}
.menuHeader > ul > li > ul > li{
    float: left;
}
.menuHeader > ul > li > ul > li > a{
    font-weight: bold;
    display: block;
}
.menuHeader > ul > li > ul > li a{
    padding: 3px 0;
    display: block;
    font-size: 12px;
    text-decoration: none;
    color: #707070;
}
.productContainer{
    width:200px;
    margin: 0 10px;
}
.productContainer .productImgContainer{
    width: 150px;
    height: 150px;
    overflow: hidden;
    text-align: center;
    margin: 10px auto;
}
.productContainer .productImgContainer img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.productInfo{
    text-align: center;
    font-size: 12px;
}
.productPrice{
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
}

.productDescriptionContainer{
    display: flex;
    box-sizing: border-box;
    padding: 10px;
}
.verticalImageStack{
    width: 67px;
    display: flex;
    flex-direction: column;
}
.thumbnail{
    width: 63px;
    height: 63px;
    border: 1px solid #f0f0f0;
    overflow: hidden;
    text-align: center;
}
.thumbnail img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.thumbnail.active{
    border: 2px solid #2874f0;
}
.productDescContainer{
    width: 485px;
}
.productDescImgContainer{
    width: 485px;
    height: 440px;
    overflow: hidden;
    text-align: center;
    border: 1px solid #f0f0f0;
}
.productDescImgContainer > img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.breed ul{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}
.breed ul li{
    list-style: none;
    margin-right: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
}
.breed ul li a{
    text-decoration: none;
    color: #777;
    display: inline-block;
    font-size: 12px;
    margin: 0 10px;
}
/* .productDetails{
    margin: 0 10px;
} */
.productDetails .productTitle{
    font-size: 18px;
    color: #212121;
    font-weight: 400;
}
.ratingCount{
    display: inline-block;
    background: #388e3c;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    border-radius: 3px;
    padding: 2px 5px;
}
.ratingNumbersReviews{
    font-size: 12px;
    color: #777;
    font-weight: 600;
    display: inline-block;
    margin: 0 10px;
}
.extraOffer, .discount{
    color: #388e3c;
    font-size: 12px;
    margin: 10px 0px;
    font-weight: 600;
}
.priceContainer{
    align-items: center;
}
.price{
    font-size: 28px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

/* Clothing Accessories Page with type as undefines */
.caContainer{
    width: 250px;
}
.caImgContainer{
    width: 240px;
    height: 300px;
    overflow: hidden;
    position: relative;
}
.caImgContainer > img{
    max-width: 100%;
    max-height: 300px;
}
.caProductName{
    font-size: 12px;
    padding: 10px 0;
}
.caProductPrice{
    font-size: 12px;
    font-weight: 600;
}

.key {
    font-size: 12px;
    font-weight: bold;
    font-family: sans-serif;
}

.value {
    font-size: 12px;
    font-family: sans-serif;
}

/* For Cart Page */
.cartContainer {
    display: flex;
    max-width: 1260px;
    margin: 15px auto;
    justify-content: space-between;
}

/* For Cart Item */
.cartItemContainer {
    margin: 10px 5px;
}

.cartProImgContainer {
    width: 112px;
    height: 112px;
    overflow: hidden;
    text-align: center;
}

.cartProImgContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.cartProImgContainer {
    width: 112px;
    height: 112px;
    overflow: hidden;
    text-align: center;
}

.cartProImgContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.cartItemDetails {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
}

.cartActionBtn {
    margin: 0 10px;
    font-family: 'Roboto';
    font-weight: 600;
    text-transform: uppercase;
    background: transparent;
    border: none;
    color: #212121;
    cursor: pointer;
    font-size: 15px;
}

.quantityControl {
    display: flex;
    align-items: center;
}

.quantityControl button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: none;
    border: 1px solid #c2c2c2;
    background-color: transparent;
}

.quantityControl input {
    width: 40px;
    height: 20px;
    border: 1px solid #c2c2c2;
    margin: 0 5px;
    text-align: center;
}





/* styles for checkout directory */
.checkoutContainer {
    width: calc(100% - 400px);
}

.checkoutStep {
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.checkoutHeader {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
}

.checkoutHeader.active {
    background: #2874f0;
}

.checkoutHeader .stepNumber {
    display: inline-block;
    text-align: center;
    background: #eee;
    color: #2874f0;
    border-radius: 3px;
    font-size: 12px;
    box-sizing: border-box;
    padding: 3px 7px;
}

.checkoutHeader.active .stepNumber {
    background: #fff;
}

.stepTitle {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #878787;
    margin-left: 15px;
}

.checkoutHeader.active .stepTitle {
    color: #fff;
}

.loggedInId {
    font-size: 12px;
    margin: 0px 0 10px 60px;
}

.addressContainer {
    box-sizing: border-box;
    padding: 20px;
    background: #f5faff;
}

.addressinfo {
    padding-left: 15px;
    box-sizing: border-box;
    width: 100%;
}

.addressDetail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 20px;
    font-weight: 600;
    font-family: 'Roboto';
    font-size: 12px;
    color: #333;
}

.addressType {
    display: inline-block;
    margin: 0 10px;
    text-transform: uppercase;
    background: #eee;
    font-weight: 500;
    padding: 1px 5px;
    font-size: 10px;
    border-radius: 2px;
    color: #777777;
}

.fullAddress {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
}

.stepCompleted {
    padding: 0 0 20px 60px;
    font-size: 12px;
}







/* styles for order directory */
/* Order Details Page style */
.delAdrContainer {
    display: flex;
    box-sizing: border-box;
}

.delTitle {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.delName {
    font-size: 12px;
    font-weight: 500;
}

.delAddress {
    font-size: 12px;
    margin: 10px 0;
}

.delPhoneNumber {
    font-size: 12px;
    font-weight: 500;
}

.delAdrDetails {
    padding: 20px;
    border-right: 1px solid #eee;
}

.delMoreActionContainer {
    padding: 20px;
}

.delItemImgContainer {
    width: 75px;
    height: 75px;
    overflow: hidden;
    text-align: center;
}

.delItemImgContainer>img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.orderTrack {
    width: 400px;
    display: flex;
}

.orderStatus {
    width: 33.33%;
    height: 3px;
    background: #777;
    position: relative;
    font-size: 12px;
}

.orderStatus.active {
    background: #26A541;
}

.orderStatus:last-child {
    width: 0;
}

.orderStatus .point {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 5px;
    background-color: #777;
    top: -3px;
}

.orderStatus .point.active {
    background-color: #26A541;
}

.orderInfo {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -26px;
    transform: translateX(-50%);
}

.delItemName {
    font-size: 12px;
}

.orderInfo .status {
    color: #26A541;
    text-transform: capitalize;
    font-weight: bold;
}

.orderInfo .date {
    font-size: 10px;
}




/* Order Page Style */
.orderItemContainer {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
}

.orderImgContainer {
    width: 80px;
    height: 80px;
    overflow: hidden;
    text-align: center;
}

.orderImg {
    max-width: 80px;
    max-height: 80px;
}

.orderRow {
    display: flex;
    flex: 1 1;
}

.orderName {
    width: 300px;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 12px;
}

.orderPrice {
    box-sizing: border-box;
    padding: 0 40px;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
}

/* Admin Order Page */
.orderTrack {
    width: 500px;
    display: flex;
}

.orderStatus {
    width: 33.33%;
    height: 5px;
    background: #777;
    position: relative;
    font-size: 12px;
}

.orderStatus.active {
    background: #26A541;
}

.orderStatus:last-child {
    width: 0;
}

.orderStatus .point {
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 8px;
    background-color: #777;
    top: -5px;
}

.orderStatus .point.active {
    background-color: #26A541;
}

.orderInfo {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -26px;
    transform: translateX(-50%);
}

.title {
    font-size: 14px;
    font-weight: 600;
}

.value {
    font-size: 12px;
    text-transform: capitalize;
}

.grecaptcha-badge{
    width: 0px;
    height: 0px;
}


/* Hotel card Style CSS */


  
  .gallery {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    /* grid-template-rows: repeat(8, 9vw); */
    grid-gap: 0.8rem; 
    margin-bottom: 0px;
  }
  
  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    display: block; 
  }
  
  .gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 2;  */
    /* grid-row: 1 / span 2; */
  }
  
  .gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  
    /** Alternative Syntax **/
    /* grid-column: 3 / span 2;  */
    /* grid-row: 1 / span 2; */
  }
  
  .gallery__item--3 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 5;
  
    /** Alternative Syntax **/
    /* grid-column: 5 / span 4;
    grid-row: 1 / span 5; */
  }
  
  .gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 5;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 4;  */
    /* grid-row: 3 / span 3; */
  }
  
  .gallery__item--5 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 5;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 4; */
    /* grid-row: 6 / span 3; */
  }
  
  .gallery__item--6 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-end: 9;
  
    /** Alternative Syntax **/
    /* grid-column: 5 / span 4; */
    /* grid-row: 6 / span 3; */
  }


  /* Hotel Section button Gradient */

  .blue-gradient{
    background: rgb(76,170,253);
    background: linear-gradient(90deg, rgba(76,170,253,1) 0%, rgba(20,106,245,1) 100%); 
  }
.professional-list {
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .professional-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .professional-card {
    border: 2px solid #3498db;
    border-radius: 12px;
    margin: 10px;
    padding: 10px;
    width: 400px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: scale(1.02);
      }
  }
  
  .card-header {
    padding: 15px;
    text-align: center;
    background-color: #3498db;
    color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .card-header img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  .card-body {
    padding: 15px;
  }
  
  /* ul {
    list-style: none;
    padding: 0;
  } */
  
  .professional-text-primary {
    color: #3498db;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
  
  .professional-btn {
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
  }
  
  .view-profile-btn {
    background-color: #2ecc71;
  }
  
  .call-btn {
    background-color: #e74c3c;
  }
  
  .info-btn {
    width: 100%;
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    .professional-card {
      width: 100%;
    }
  }
  
