/* Global Styles */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

*{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
  scrollbar-color: transparent transparent; 
}
*::-webkit-scrollbar{
  display: none; 
}


body {
  font-family: "Lato",'Ubuntu', sans-serif;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}



/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
/* .p-1 {
  padding: 1rem;
} */
/* .p-2 {
  padding: 2rem;
} */
/* .p-3 {
  padding: 3rem;
} */
.py {
  padding: 0.5rem 0;
}
/* .py-1 {
  padding: 1rem 0;
} */
/* .py-2 {
  padding: 2rem 0;
} */
/* .py-3 {
  padding: 3rem 0;
} */

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
/* .m-2 {
  margin: 2rem;
} */
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
/* .my-2 {
  margin: 2rem 0;
} */
/* .my-3 {
  margin: 3rem 0;
} */






.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.sub-btn {
  margin: 0px;
  margin-top: 20px;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

/* .btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
} */

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

/* .form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.3rem;
  font-size: 1.0rem;
  border: 1px solid #ccc;
} */

.form input[type='submit'],
/* button {
  font: inherit;
} */

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: relative;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: inline;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Landing Page */
.landing {
  position: relative;
  /* background: url('./img/employment.jpg') no-repeat center center/cover; */
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo > div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github > div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post > div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* .flex {
  display: flex;
} */

.flex-wrap {
  flex-wrap: wrap;
}

/* .flex-col {
  flex-direction: column !important;
} */

.gap-12px {
  gap: 12px
}

.row-gap-16px {
  row-gap: 16px
}

.justify-between {
  justify-content: space-between;
}

/* .job {
  width: 300px;
} */

.width-50 {
  width: 50%;
}

.width-30 {
  width: 30%
}

.height-30px {
  height: 40px;
}

.margin-0 {
  margin: 0px !important;
}

.rounded-4 {
  border-radius: 4px;
}

.text-xs {
	font-size: 12px;
	line-height: 16px;
}
/* .text-sm {
	font-size: 14px;
	line-height: 20px;
} */
.text-base {
	font-size: 16px;
	line-height: 24px;
}
/* .text-xl {
	font-size: 20px;
	line-height: 28px;
} */
/* .text-lg {
	font-size: 18px;
	line-height: 28px;
} */
/* .text-2xl {
	font-size: 24px;
	line-height: 32px;
} */

.footer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.footer .footer-inside {
  padding: 24px 0 8px;
  font-size: 14px;
}

.u7OzwiLi {
  opacity: 1 !important;
}

.BdwL79Y_ {
  background: #000;
  z-index: 3;
  position: relative;
  width: 100%;
}

/* ********************** Invoices  ************************ */
/* Register Professional */


.invoice-card {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 10px 2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 25em;
  width: 22em;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.15);
}

.invoice-card > div {
  margin: 5px 0;
}

.invoice-title {
  flex: 3;
}

.invoice-title #date {
  display: block;
  margin: 8px 0;
  font-size: 12px;
}

.invoice-title #main-title {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

.invoice-title #main-title h4 {
  letter-spacing: 2.5px;
}

.invoice-title span {
  color: rgba(0, 0, 0, 0.4);
}

.invoice-details-doc {
  flex: 1;
  border-top: 0.5px dashed grey;
  border-bottom: 0.5px dashed grey;
  display: flex;
  align-items: center;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table thead tr td {
  font-size: 12px;
  letter-spacing: 1px;
  color: grey;
  padding: 8px 0;
}

.invoice-table thead tr td:nth-last-child(1),
.row-data td:nth-last-child(1),
.calc-row td:nth-last-child(1)
{
  text-align: right;
}

.invoice-table tbody tr td {
    padding: 8px 0;
    letter-spacing: 0;
}

.invoice-table .row-data #unit {
  text-align: center;
}

.invoice-table .row-data span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.invoice-footer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.invoice-footer #later {
  margin-right: 5px;
}

/* Tiles for Register Page  */

.card-category-1 div {
  display: inline-block;
}
 
.card-category-1 > div {
  margin: 10px 5px;
  text-align: left;
}
 
/* Basic Card */
.basic-card {
  width: 300px;
  position: relative;
 
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}
 
.basic-card .card-content {
  padding: 30px;
}
 
.basic-card .card-text {
  line-height: 1.6;
}
 
.basic-card .card-link {
  padding: 25px;
  width: -webkit-fill-available;
}
 
.basic-card .card-link a {
  text-decoration: none;
  position: relative;
  padding: 10px 0px;
}
 
.basic-card .card-link a:after {
  top: 30px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 0;
 
  -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}
 
.basic-card .card-link a:hover:after {
  width: 100%;
  left: 0;
}
 
.basic-card-aqua {
  background-image: linear-gradient(to bottom right, #00bfad, #99a3d4);
}

.basic-card-aqua-job {
  background-image: linear-gradient(to bottom right, hsl(125, 100%, 37%), #99a3d4);
}

.basic-card-aqua-service {
  background-image: linear-gradient(to bottom right, hsl(53, 100%, 37%), #ff0000);
}

.basic-card-aqua-service-provider {
  background-image: linear-gradient(to bottom right, hsl(178, 100%, 37%), #a3d499);
}
 
 
.basic-card-aqua .card-content,
.basic-card .card-link a {
  color: #fff;
}
 
.basic-card-aqua .card-link {
  border-top: 1px solid #82c1bb;
}
 
.basic-card-aqua .card-link a:after {
  background: #fff;
}

/* Tiles closed */

/* Mobile Styles */
@media (max-width: 700px) {

  .form .form-group {
    margin: 1.2rem 0;
  }

  .form {
    display: block;
    margin-top: 0.3rem;
    color: #888;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 1fr;
  }
  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }

  .form-wrapper {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
  
  .input-field {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    padding: 0.5rem 0rem;
  }
  .input-field label {
    color: rgb(133, 133, 133);
    font-size: 14px;
  }
  .main-heading {
    color: #212121;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }
  .sub-text {
    color: #424242;
    font-size: 15px;
  }
  .wrapper {
    max-width: 580px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 1rem;
    margin: 0 auto;
  }
  .sub-text {
    color: #424242;
    font-size: 1rem;
  }

  .job {
    width: 300px;
  }
}

.text-gradient {
  background-image: rgb(233,131,21);
  background-image: linear-gradient(52deg, rgba(233,131,21,0.8637413394919169) 36%, rgba(10,137,1,1) 100%);
  -webkit-background-clip: text; /* Clip the background to the text */
  color: transparent;
}

.btn-grad {
  background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%);
  margin: 10px;
  padding: 10px 40px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.neo{
  border-radius: 22px;
  background: #ffffff;
  box-shadow:  5px 5px 10px #dbdbdb,
               -5px -5px 10px #ffffff;
}

.inset-shadow{
  border-radius: 11px;
  background: linear-gradient(145deg, #d3d2d2, #ffffff);
  box-shadow:  5px 5px 10px #dbdbdb,
               -5px -5px 10px #ffffff;
}