
.key {
    font-size: 12px;
    font-weight: bold;
    font-family: sans-serif;
}

.value {
    font-size: 12px;
    font-family: sans-serif;
}

/* For Cart Page */
.cartContainer {
    display: flex;
    max-width: 1260px;
    margin: 15px auto;
    justify-content: space-between;
}

/* For Cart Item */
.cartItemContainer {
    margin: 10px 5px;
}

.cartProImgContainer {
    width: 112px;
    height: 112px;
    overflow: hidden;
    text-align: center;
}

.cartProImgContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.cartProImgContainer {
    width: 112px;
    height: 112px;
    overflow: hidden;
    text-align: center;
}

.cartProImgContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.cartItemDetails {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.cartActionBtn {
    margin: 0 10px;
    font-family: 'Roboto';
    font-weight: 600;
    text-transform: uppercase;
    background: transparent;
    border: none;
    color: #212121;
    cursor: pointer;
    font-size: 15px;
}

.quantityControl {
    display: flex;
    align-items: center;
}

.quantityControl button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: none;
    border: 1px solid #c2c2c2;
    background-color: transparent;
}

.quantityControl input {
    width: 40px;
    height: 20px;
    border: 1px solid #c2c2c2;
    margin: 0 5px;
    text-align: center;
}





/* styles for checkout directory */
.checkoutContainer {
    width: calc(100% - 400px);
}

.checkoutStep {
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.checkoutHeader {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
}

.checkoutHeader.active {
    background: #2874f0;
}

.checkoutHeader .stepNumber {
    display: inline-block;
    text-align: center;
    background: #eee;
    color: #2874f0;
    border-radius: 3px;
    font-size: 12px;
    box-sizing: border-box;
    padding: 3px 7px;
}

.checkoutHeader.active .stepNumber {
    background: #fff;
}

.stepTitle {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #878787;
    margin-left: 15px;
}

.checkoutHeader.active .stepTitle {
    color: #fff;
}

.loggedInId {
    font-size: 12px;
    margin: 0px 0 10px 60px;
}

.addressContainer {
    box-sizing: border-box;
    padding: 20px;
    background: #f5faff;
}

.addressinfo {
    padding-left: 15px;
    box-sizing: border-box;
    width: 100%;
}

.addressDetail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 20px;
    font-weight: 600;
    font-family: 'Roboto';
    font-size: 12px;
    color: #333;
}

.addressType {
    display: inline-block;
    margin: 0 10px;
    text-transform: uppercase;
    background: #eee;
    font-weight: 500;
    padding: 1px 5px;
    font-size: 10px;
    border-radius: 2px;
    color: #777777;
}

.fullAddress {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
}

.stepCompleted {
    padding: 0 0 20px 60px;
    font-size: 12px;
}







/* styles for order directory */
/* Order Details Page style */
.delAdrContainer {
    display: flex;
    box-sizing: border-box;
}

.delTitle {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.delName {
    font-size: 12px;
    font-weight: 500;
}

.delAddress {
    font-size: 12px;
    margin: 10px 0;
}

.delPhoneNumber {
    font-size: 12px;
    font-weight: 500;
}

.delAdrDetails {
    padding: 20px;
    border-right: 1px solid #eee;
}

.delMoreActionContainer {
    padding: 20px;
}

.delItemImgContainer {
    width: 75px;
    height: 75px;
    overflow: hidden;
    text-align: center;
}

.delItemImgContainer>img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.orderTrack {
    width: 400px;
    display: flex;
}

.orderStatus {
    width: 33.33%;
    height: 3px;
    background: #777;
    position: relative;
    font-size: 12px;
}

.orderStatus.active {
    background: #26A541;
}

.orderStatus:last-child {
    width: 0;
}

.orderStatus .point {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 5px;
    background-color: #777;
    top: -3px;
}

.orderStatus .point.active {
    background-color: #26A541;
}

.orderInfo {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -26px;
    transform: translateX(-50%);
}

.delItemName {
    font-size: 12px;
}

.orderInfo .status {
    color: #26A541;
    text-transform: capitalize;
    font-weight: bold;
}

.orderInfo .date {
    font-size: 10px;
}




/* Order Page Style */
.orderItemContainer {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
}

.orderImgContainer {
    width: 80px;
    height: 80px;
    overflow: hidden;
    text-align: center;
}

.orderImg {
    max-width: 80px;
    max-height: 80px;
}

.orderRow {
    display: flex;
    flex: 1;
}

.orderName {
    width: 300px;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 12px;
}

.orderPrice {
    box-sizing: border-box;
    padding: 0 40px;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
}

/* Admin Order Page */
.orderTrack {
    width: 500px;
    display: flex;
}

.orderStatus {
    width: 33.33%;
    height: 5px;
    background: #777;
    position: relative;
    font-size: 12px;
}

.orderStatus.active {
    background: #26A541;
}

.orderStatus:last-child {
    width: 0;
}

.orderStatus .point {
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 8px;
    background-color: #777;
    top: -5px;
}

.orderStatus .point.active {
    background-color: #26A541;
}

.orderInfo {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -26px;
    transform: translateX(-50%);
}

.title {
    font-size: 14px;
    font-weight: 600;
}

.value {
    font-size: 12px;
    text-transform: capitalize;
}

.grecaptcha-badge{
    width: 0px;
    height: 0px;
}


/* Hotel card Style CSS */


  
  .gallery {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    /* grid-template-rows: repeat(8, 9vw); */
    grid-gap: 0.8rem; 
    margin-bottom: 0px;
  }
  
  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    display: block; 
  }
  
  .gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 2;  */
    /* grid-row: 1 / span 2; */
  }
  
  .gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  
    /** Alternative Syntax **/
    /* grid-column: 3 / span 2;  */
    /* grid-row: 1 / span 2; */
  }
  
  .gallery__item--3 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 5;
  
    /** Alternative Syntax **/
    /* grid-column: 5 / span 4;
    grid-row: 1 / span 5; */
  }
  
  .gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 5;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 4;  */
    /* grid-row: 3 / span 3; */
  }
  
  .gallery__item--5 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 5;
  
    /** Alternative Syntax **/
    /* grid-column: 1 / span 4; */
    /* grid-row: 6 / span 3; */
  }
  
  .gallery__item--6 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-end: 9;
  
    /** Alternative Syntax **/
    /* grid-column: 5 / span 4; */
    /* grid-row: 6 / span 3; */
  }


  /* Hotel Section button Gradient */

  .blue-gradient{
    background: rgb(76,170,253);
    background: linear-gradient(90deg, rgba(76,170,253,1) 0%, rgba(20,106,245,1) 100%); 
  }